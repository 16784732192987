<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderLink(item)"
    class="nav-item"
    :class="{'sidebar-group-active active': isActive}"
  >
    <b-link class="nav-link" :to="{ name: item.key }">
      <feather-icon
        size="14"
        style="color:black"
        :icon="styleicon(
            item.key
          )"
      />
      <span style="color:black;font-size:16px ">{{ t(item.key) }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import useHorizontalNavMenuHeaderLink from "./useHorizontalNavMenuHeaderLink";
import mixinHorizontalNavMenuHeaderLink from "./mixinHorizontalNavMenuHeaderLink";

export default {
  components: {
    BLink
  },
  data() {
    return {};
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item
    );

    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils();

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t
    };
  },
  methods: {
    styleicon(key) {
      if (key == "urunler") {
        return "ListIcon";
      } else if (key == "siparislerim") {
        return "CreditCardIcon";
      } else if (key == "cariHesap") {
        return "BarChart2Icon";
      } else if (key == "tekliftopla") {
        return "ListIcon";
      } else if (key == null) {
        return "RefreshCcwIcon";
      }
    }
  }
};
</script>
