<template>
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <component
      :style="` display:${styleSepet(
            item.key
          )}`"
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <!-- <li
      class="nav-item"
      :class="{'sidebar-group-active active': isActive}"
      style=" margin-left: auto; "
    >
      <b-link class="nav-link" to="sepetim">
        <feather-icon size="14" icon="ShoppingCartIcon" style="color:black;" />
        <span style="color:black;font-size:16px ">{{ $t('sepetim') }}</span>
      </b-link>
    </li> -->
  </ul>
</template>

<script>
import { BLink } from "bootstrap-vue";
import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue";
import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
    BLink
  },
  data() {
    return {
      isActive: false,
      currentPath: ""
    };
  },

  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    const { t } = useI18nUtils();
    const resolveNavComponent = item => {
      if (item.children) return "horizontal-nav-menu-header-group";
      return "horizontal-nav-menu-header-link";
    };

    return {
      resolveNavComponent
    };
  },
  watch: {
    $route(to, from) {
      this.currentPath = to.path;
      if (this.currentPath == "/sepetim") {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    }
  },
  mounted() {
    this.currentPath = this.$route.path;
    if (this.currentPath == "/sepetim") {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  },
  methods: {
    styleSepet(key) {
      if (key == "sepetim") {
        return "none";
      } else if (key == null) {
        return "none";
      } else if (key == "") {
        return "none";
      }
    }
  }
};
</script>
